import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Fallback } from 'router/Fallback';
import { RequireAuth } from 'router/RequireAuth';

const AccountPage = lazy(() => import('pages/account/AccountContainer'));
const AccountDeletePage = lazy(() => import('pages/account/AccountRemoval'));
const AccountDeleteConfirmedPage = lazy(() => import('pages/account/AccountRemovalConfirmation'));
const AccountRecovery = lazy(() => import('pages/account/AccountRecovery'));
const AccountEmailConfirmation = lazy(() => import('pages/account/AccountEmailConfirmation'));
const AccountEmailVerification = lazy(() => import('pages/account/AccountEmailVerification'));

export const AccountRoutes = [
  {
    path: 'account',
    element: (
      <Suspense fallback={<Fallback />}>
        <RequireAuth>
          <Outlet />
        </RequireAuth>
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<Fallback />}>
            <AccountPage />
          </Suspense>
        ),
      },
      {
        path: 'recover/:token',
        element: (
          <Suspense fallback={<Fallback />}>
            <AccountRecovery />
          </Suspense>
        ),
      },
      {
        path: 'delete',
        element: (
          <Suspense fallback={<Fallback />}>
            <AccountDeletePage />
          </Suspense>
        ),
      },

      {
        path: 'email',
        children: [
          {
            path: 'confirmation',
            element: (
              <Suspense fallback={<Fallback />}>
                <AccountEmailConfirmation />
              </Suspense>
            ),
          },
          {
            path: 'verify/:token',
            element: (
              <Suspense fallback={<Fallback />}>
                <AccountEmailVerification />
              </Suspense>
            ),
          },
          {
            path: 'confirm/:token',
            element: (
              <Suspense fallback={<Fallback />}>
                <AccountEmailVerification />
              </Suspense>
            ),
          },
          {
            path: 'recover/:token',
            element: (
              <Suspense fallback={<Fallback />}>
                <AccountEmailVerification />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  {
    path: 'account/delete-confirmed',
    element: (
      <Suspense fallback={<Fallback />}>
        <AccountDeleteConfirmedPage />
      </Suspense>
    ),
  },
];
