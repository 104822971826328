import { FC } from 'react';
import { NavItem } from 'interfaces/NavTypes';
import { Link } from '@mui/material';

import { getRouteProps } from 'utils/getRouteProps';

type HeaderLinkProps = {
  item: NavItem;
};

export const HeaderLink: FC<HeaderLinkProps> = ({ item }) => {
  const props = getRouteProps(item);

  return (
    <Link
      sx={{
        'p': 2,
        'fontWeight': 600,
        '&:hover': { textDecorationColor: (theme) => theme.palette.primary.main },
      }}
      {...props}
    >
      {item.title}
    </Link>
  );
};
