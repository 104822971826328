import { createContext, FC, PropsWithChildren, Reducer, useContext, useMemo, useReducer, useState } from 'react';
import { StudioAction, StudioContextValue, StudioState } from 'interfaces/StudioContext';

import { ContentAPI } from 'api/ContentAPI';
import { GET_SETS, GET_UNIT, init, initialState, reducer } from 'reducers/studio-reducer';

const StudioContext = createContext<StudioContextValue | undefined>(undefined);

export const StudioContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<StudioState, StudioAction>, typeof initialState>(
    reducer,
    initialState,
    init,
  );
  const [loading, setLoading] = useState(true);

  const getSet = async (id: string) => {
    try {
      setLoading(true);
      const { status, parsed, errors } = await ContentAPI.getSetDetail(id);
      if (status) {
        return parsed.data;
      } else {
        console.log(errors);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getSets = async (id: string) => {
    try {
      const {
        status,
        parsed: { data: sets },
      } = await ContentAPI.getSetsForUnit(id);
      if (status) dispatch({ type: GET_SETS, sets });
      return sets;
    } catch (error) {
      console.log(error);
    }
  };

  const getUnit = async (id: string) => {
    try {
      const {
        status,
        parsed: { data: unit },
      } = await ContentAPI.getUnit(id);
      if (status) dispatch({ type: GET_UNIT, unit });
      return unit;
    } catch (error) {
      console.log(error);
    }
  };

  const value = useMemo(
    () => ({
      state,
      dispatch,
      loading,
      setLoading,
      getSet,
      getSets,
      getUnit,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state, loading],
  );

  return <StudioContext.Provider value={value}>{children}</StudioContext.Provider>;
};

export const useStudio = () => {
  const studioContext = useContext(StudioContext);

  if (!studioContext) throw Error('useStudio: The hook is used outside its context.');

  return studioContext;
};
