import { CardActionArea, CardActions, CardContainer, CardContent, CardHeader, CardMedia } from './components';

export const Card = {
  ActionArea: CardActionArea,
  Actions: CardActions,
  Container: CardContainer,
  Content: CardContent,
  Header: CardHeader,
  Media: CardMedia,
};
