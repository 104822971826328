import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

export const defaultNS = 'translation';

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    defaultNS,
    fallbackLng: {
      'nl': ['nl-NL', 'en'],
      'nl-NL': ['en'],
      'da-DK': ['da', 'en'],
      'de': ['de-DE', 'en-GB', 'en'],
      'de-DE': ['en-GB', 'en'],
      'en-AU': ['en-GB', 'en'],
      'en-UK': ['en-GB', 'en'],
      'en-GB': ['en'],
      'en-US': ['en-GB', 'en'],
      'en-IE': ['en-GB', 'en'],
      'fr': ['fr-FR', 'en'],
      'fr-FR': ['en'],
      'fr-BE': ['fr-FR', 'en'],
      'nl-BE': ['nl-NL', 'en'],
      'no-NO': ['en-GB', 'en'],
      'sv': ['sv-SE', 'en'],
      'sv-SE': ['sv', 'en'],
      'default': ['en'],
    },
    detection: {
      order: ['cookie', 'localStorage', 'sessionStorage', 'navigator'],
    },
    debug: process.env.NODE_ENV !== 'production',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath: process.env.REACT_APP_MYTOVER_API + '/lang/{{lng}}/{{ns}}.json?v=' + Date.now(),
      addPath: process.env.REACT_APP_MYTOVER_API + '/translation/add/{{lng}}/{{ns}}',
      allowMultiLoading: false,
    },
    saveMissing: true,
    appendNamespaceToMissingKey: true,
  });

export default i18n;
