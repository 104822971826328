import { lazy, Suspense } from 'react';

import { Fallback } from 'router/Fallback';

const RegisterLayout = lazy(() => import('pages/Register'));
const FormConfigurationKey = lazy(() => import('components/register/FormConfigurationKey'));
const UnlockSubscription = lazy(() => import('components/register/UnlockSubscription'));
const ContactPartner = lazy(() => import('components/register/ContactPartner'));
const FormSerialNumber = lazy(() => import('components/register/FormSerialNumber'));

export const TovertafelRegistrationRoutes = [
  {
    path: 'register',
    element: (
      <Suspense fallback={<Fallback />}>
        <RegisterLayout />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<Fallback />}>
            <FormSerialNumber />
          </Suspense>
        ),
      },
      {
        path: 'configuration',
        element: (
          <Suspense fallback={<Fallback />}>
            <FormConfigurationKey />
          </Suspense>
        ),
      },
      {
        path: 'subscriptions',
        element: (
          <Suspense fallback={<Fallback />}>
            <UnlockSubscription />
          </Suspense>
        ),
      },
      {
        path: 'contact',
        element: (
          <Suspense fallback={<Fallback />}>
            <ContactPartner />
          </Suspense>
        ),
      },
      // There's a misunderstanding how the registration QR code is generated. To
      // manage this miscommunication, we handle those URLs as well where the
      // register part of the URL is doubled. So we can be sure all the case is
      // covered
      {
        path: 'register',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<Fallback />}>
                <FormSerialNumber />
              </Suspense>
            ),
          },
          {
            path: ':serial',
            element: (
              <Suspense fallback={<Fallback />}>
                <FormSerialNumber />
              </Suspense>
            ),
          },
        ],
      },
      // There's a version of the unit registration URL where the serial number
      // comes in as part of the URL instead of a search parameter. Handling that
      // in the same component just to be sure
      {
        path: ':serial',
        element: (
          <Suspense fallback={<Fallback />}>
            <FormSerialNumber />
          </Suspense>
        ),
      },
    ],
  },
];
