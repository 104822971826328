import { lazy, Suspense } from 'react';

import { Fallback } from '../Fallback';

const PrivacyPolicyPage = lazy(() => import('pages/mytover/policies/PrivacyPolicy'));
const EndUserLicenseAgreement = lazy(() => import('pages/mytover/policies/EndUserLicenseAgreement'));
const ProcessingConditions = lazy(() => import('pages/mytover/policies/ProcessingConditions'));

function ProcessingConditionsPage() {
  return (
    <Suspense fallback={<Fallback />}>
      <ProcessingConditions />
    </Suspense>
  );
}

export const LegalRoutes = [
  {
    path: 'privacy',
    element: (
      <Suspense fallback={<Fallback />}>
        <PrivacyPolicyPage />
      </Suspense>
    ),
  },
  {
    path: 'eula',
    element: (
      <Suspense fallback={<Fallback />}>
        <EndUserLicenseAgreement />
      </Suspense>
    ),
  },
  {
    path: 'processingterms', //used in marketing emails
    element: ProcessingConditionsPage(),
  },
  {
    path: 'processing-conditions',
    element: ProcessingConditionsPage(),
  },
];
