import { FC } from 'react';
import { CardActions as MuiCardActions, CardActionsProps } from '@mui/material';

export const CardActions: FC<CardActionsProps> = ({ children, sx, ...props }) => (
  <MuiCardActions
    {...props}
    sx={{
      'p': 4,
      'mt': 'auto',
      'flexDirection': { xs: 'column', sm: 'row' },
      'gap': 2,
      '&>:not(:first-of-type)': { ml: 0 },
      ...sx,
    }}
  >
    {children}
  </MuiCardActions>
);
