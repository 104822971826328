import { CircularProgress, Grid } from '@mui/material';

const CenteredCircularProgress = () => {
  return (
    <Grid
      container
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minHeight: '100%',
      }}
      justifyContent="center"
      alignContent="center"
    >
      <Grid item component={CircularProgress} />
    </Grid>
  );
};
export default CenteredCircularProgress;
