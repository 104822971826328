import { lazy, Suspense } from 'react';

import { Fallback } from 'router/Fallback';

const ServiceCenterHome = lazy(() => import('pages/service-center/ServiceCenterHome'));
const ServiceCenterUpdates = lazy(() => import('pages/service-center/ServiceCenterUpdates'));

export const ServiceCenterRoutes = [
  {
    path: 'service-center',
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<Fallback />}>
            <ServiceCenterHome />
          </Suspense>
        ),
      },
      {
        path: 'updates',
        element: (
          <Suspense fallback={<Fallback />}>
            <ServiceCenterUpdates />
          </Suspense>
        ),
      },
    ],
  },
];
