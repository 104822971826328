import { useTranslation } from 'react-i18next';
import { ChatBubbleOutlineOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

interface ButtonProps {
  openDialog: any;
}

const Root = styled('button')(({ theme }) => ({
  'position': 'fixed',
  'top': '38vh',
  'right': '0',
  'zIndex': '100',
  'padding': '0.5rem 1rem 1rem 1rem',
  'fontSize': 'smaller',
  'backgroundColor': theme.palette.secondary.main,
  'color': theme.palette.common.white,
  'borderRadius': '10px 10px 0 0',
  'transform': 'translateX(50%) rotate(-90deg) translateY(-30%)',
  'transition': 'background-color 0.3s, color 0.3s, transform 0.3s',
  'boxShadow':
    'rgba(0, 0, 0, 0.14) 0px 2.96875px 3px 0px, rgba(0, 0, 0, 0.12) 0px 2.015625px 5px -1.015625px, rgba(0, 0, 0, 0.2) 0px 1.484375px 5px -0.484375px',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    transform: 'translateX(50%) rotate(-90deg) translateY(-40%)',
  },
  [theme.breakpoints.up('xl')]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.black,
  },
}));

const FeedbackButton = ({ openDialog }: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <Root className="feedbackButton" onClick={openDialog}>
      <div className="feedbackButton--content">
        <ChatBubbleOutlineOutlined sx={{ mr: 1, height: '1.4rem', width: '1.4rem' }} />
        &nbsp;{t('feedback.button')}
      </div>
    </Root>
  );
};
export default FeedbackButton;
