import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';

import { stringToSlug } from 'utils/slugify';
import { Card } from 'components/shared/Card';

const ContactCard = () => {
  const { t } = useTranslation();

  const slugOptions = { lower: true, strict: true };

  return (
    <Card.Container
      sx={{
        flexDirection: {
          xs: 'column-reverse',
          sm: 'row',
        },
      }}
    >
      <Box
        flex={{
          xs: '1 0 100%',
          sm: '1 0 50%',
        }}
      >
        <Card.Header title={t('service-center.contact.title')} />
        <Card.Content>{t('service-center.contact.description')}</Card.Content>
        <Card.Actions>
          <Button component={Link} to={`/articles/${stringToSlug(t('footer.contact.title'), slugOptions)}`}>
            {t('service-center.contact.button')}
          </Button>
        </Card.Actions>
      </Box>
      <Card.Media
        alt={t('service-center.contact.image.alt')}
        image={`${process.env.PUBLIC_URL}/images/banners/service-contact.jpg`}
      />
    </Card.Container>
  );
};

export default ContactCard;
