import { ElementType } from 'react';
import { Link } from 'react-router-dom';
import { NavItem } from 'interfaces/NavTypes';

type Props = {
  key: string;
  component: ElementType;
  href?: string;
  to?: string;
};

type GetRouteProps = (item: NavItem) => Props;

export const getRouteProps: GetRouteProps = ({ title, route }) => {
  const props: Props = {
    key: title,
    component: Link,
  };

  if (route.match(/^http/)) {
    props.href = route;
    props.component = 'a';
  } else {
    props.to = route;
  }

  return props;
};
