import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert, Box } from '@mui/material';

import { DefaultLayout } from 'components/page/layouts/DefaultLayout';
import PageSectionContainer from 'components/page/PageSectionContainer';

export const GuestModeBanner: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <DefaultLayout>
      {/* Alert info color does not match w/ the MUI library blue || lightBlue palettes */}
      <Box
        onClick={() => navigate('/signin', { state: { from: location } })}
        sx={{ backgroundColor: '#e5f6fd', zIndex: 10, cursor: 'pointer' }}
      >
        <PageSectionContainer
          sx={{
            gap: 0,
            padding: 0,
          }}
        >
          <Alert severity="info" sx={{ paddingInline: 0 }}>
            {t('guest.banner')}
          </Alert>
        </PageSectionContainer>
      </Box>
    </DefaultLayout>
  );
};
