import './index.scss';
import './i18n/config';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { SnackbarProvider } from 'notistack';
import { CssBaseline, ThemeProvider } from '@mui/material';

import theme from 'theme';
import { AuthContextProvider } from 'contexts/AuthContext';
import { EventContextProvider } from 'contexts/EventContext';
import { FeedbackContextProvider } from 'contexts/FeedbackContext';
import { GlobalContextProvider } from 'contexts/GlobalContext';

import App from './App';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalContextProvider>
        <EventContextProvider>
          <AuthContextProvider>
            <FeedbackContextProvider>
              <SnackbarProvider anchorOrigin={{ horizontal: 'center', vertical: 'top' }} maxSnack={3}>
                <App />
              </SnackbarProvider>
            </FeedbackContextProvider>
          </AuthContextProvider>
        </EventContextProvider>
      </GlobalContextProvider>
    </ThemeProvider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
