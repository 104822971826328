import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Locale } from 'interfaces/Locale';
import { Box, IconButton, ListItemSecondaryAction, ListItemText, Menu, MenuItem, MenuProps } from '@mui/material';
import { Check, Close, Language } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import { useGlobal } from 'contexts/GlobalContext';

const PREFIX = 'HeaderLanguageSelection';

const classes = {
  item: `${PREFIX}-item`,
  icon: `${PREFIX}-icon`,
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    background: theme.palette.primary.main,
    borderRadius: 10,
    borderTopRightRadius: 0,
    minWidth: 250,
  },
  [`& .${classes.item}`]: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  [`& .${classes.icon}`]: {
    borderRadius: '50%',
    height: 20,
    marginRight: theme.spacing(2),
    minWidth: 20,
    overflow: 'hidden',
    width: 20,
  },
}));

export const HeaderLanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const {
    state: { languages },
    getLanguages,
  } = useGlobal();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (open) getLanguages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languages, open]);

  if (!languages) return null;

  return (
    <Box sx={{ mr: 2 }}>
      <IconButton
        aria-controls="language-menu"
        aria-haspopup="true"
        color="inherit"
        edge="end"
        onClick={handleClick}
        sx={{
          'backgroundColor': open ? 'primary.main' : undefined,
          'border': '1px solid',
          'borderColor': open ? 'primary.main' : 'common.white',
          'borderRadius': open ? '10px 20px 0 0' : undefined,
          'color': open ? 'secondary.main' : undefined,
          '&:hover': {
            backgroundColor: 'primary.main',
            borderColor: 'primary.main',
          },
        }}
      >
        {open ? <Close /> : <Language />}
      </IconButton>
      <StyledMenu id="language-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {languages.map((item: Locale, index) => (
          <MenuItem
            className={classes.item}
            key={`language-${index}`}
            onClick={() => {
              i18n.changeLanguage(item.code);
              setAnchorEl(null);
            }}
          >
            <ListItemText primary={t(item.label)} />
            {i18n.language === item.code && (
              <ListItemSecondaryAction>
                <Check />
              </ListItemSecondaryAction>
            )}
          </MenuItem>
        ))}
      </StyledMenu>
    </Box>
  );
};
