import React from 'react';
import { DialogActions, DialogContent, IconButton, Paper, Snackbar, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const TitleWithClose = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <Typography variant="h4" component="h6" sx={{ pt: 2, px: 3 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: (theme) => theme.spacing(1),
            top: (theme) => theme.spacing(1),
            color: (theme) => theme.palette.grey[700],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </Typography>
  );
};

const NonmodalDialog = ({ title, children, actions, open, onClose: handleClose }: any) => {
  return (
    <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={open} onClose={handleClose}>
      <Paper
        elevation={3}
        sx={{
          borderRadius: '10px',
          p: {
            xs: 1,
            sm: 2,
          },
          maxWidth: '100%',
        }}
      >
        <TitleWithClose id="customized-dialog-title" onClose={handleClose}>
          {title}
        </TitleWithClose>
        <DialogContent>{children}</DialogContent>
        {actions && <DialogActions sx={{ flexWrap: 'wrap', p: 0 }}>{actions}</DialogActions>}
      </Paper>
    </Snackbar>
  );
};

export default NonmodalDialog;
