import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumb } from 'interfaces/ZendeskArticle';
import { Breadcrumbs, Link, Typography } from '@mui/material';

const PageBreadcrumbs = ({ crumbs }: { crumbs?: Breadcrumb[] }) => {
  const { t } = useTranslation();

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      itemsBeforeCollapse={2}
      itemsAfterCollapse={2}
      maxItems={4}
      separator="⟩"
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        paddingBlock: 3,
        paddingInline: 0,
      }}
    >
      <Link component={RouterLink} key="crumb_0" to="/" underline="none" variant="caption">
        {t('breadcrumbs.home', 'Home')}
      </Link>

      {crumbs &&
        crumbs.map((crumb, i) =>
          i + 1 < crumbs.length ? (
            <Link component={RouterLink} key={`crumb_${i}`} to={crumb.route} underline="none" variant="caption">
              {crumb.name}
            </Link>
          ) : (
            <Typography key={`crumb_${i}`} component="span" variant="caption">
              {crumb.name}
            </Typography>
          ),
        )}
    </Breadcrumbs>
  );
};

export default PageBreadcrumbs;
