export const INIT_SESSION_STORAGE = 'INIT_SESSION_STORAGE';
export const GET_SESSION_STORAGE = 'GET_SESSION_STORAGE';
export const SET_SESSION_STORAGE = 'SET_SESSION_STORAGE';
export const RESET = 'RESET';

const eventNames = ['end-of-page', 'create-set', 'creations-sync', 'library-sync'] as const;
// eslint-disable-next-line prettier/prettier
export type FeedbackEventName = (typeof eventNames)[number];
export const isValidSection = (x: any): x is FeedbackEventName => eventNames.includes(x);

export type SessionMap = {
  [name in FeedbackEventName]: boolean;
};

export interface FeedbackAction {
  type: string;
  name?: FeedbackEventName;
}

export const initialState = {
  'end-of-page': true,
  'create-set': true,
  'creations-sync': true,
  'library-sync': true,
};

export const init = (initialState: SessionMap) => initialState;

export const reducer = (state: SessionMap, action: FeedbackAction) => {
  switch (action.type) {
    case INIT_SESSION_STORAGE: {
      return initializeSessionStorage(state);
    }
    case GET_SESSION_STORAGE: {
      return getSessionStorage();
    }
    case SET_SESSION_STORAGE: {
      return updateSessionStorage(state, action.name!);
    }
    case RESET:
      clearSessionStorage();
      return init(initialState);
    default:
      throw new Error('Unknown feedback action');
  }
};

const getSessionStorage = () => {
  if (sessionStorage['feedback-session']) {
    return JSON.parse(sessionStorage.getItem('feedback-session')!);
  } else {
    return null;
  }
};

const initializeSessionStorage = (state: SessionMap) => {
  const activeSessionData = getSessionStorage();
  if (activeSessionData) {
    return { ...activeSessionData };
  } else {
    setSessionStorage(initialState);
    return { ...state };
  }
};

const updateSessionStorage = (state: SessionMap, name: FeedbackEventName) => {
  const activeSessionData = getSessionStorage();

  if (activeSessionData) {
    const newState = {
      ...activeSessionData,
      [name]: false,
    };

    setSessionStorage(newState);
    return newState;
  } else {
    const newState = {
      ...state,
      [name]: false,
    };
    setSessionStorage(newState);
    return newState;
  }
};

const setSessionStorage = (data: any) => {
  sessionStorage.setItem('feedback-session', JSON.stringify(data));
};

const clearSessionStorage = () => {
  sessionStorage.removeItem('feedback-session');
};
