import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

export const RedirectToHome: FC = () => {
  const location = useLocation();
  const url = location.pathname.startsWith('/mytover')
    ? location.pathname === '/mytover'
      ? '/'
      : location.pathname.replace('/mytover', '')
    : location.pathname;

  return <Navigate to={url} replace />;
};
