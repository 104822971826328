import { createContext, ReactNode } from 'react';

interface IEventContext {
  emitCustomEvent: (type: string, options: CustomEventOptions) => void;
}

interface CustomEventOptions {
  action: string;
}

export interface CustomEventDetail {
  name: string;
}

export const EventContext = createContext<IEventContext | undefined>(undefined);

export const EventContextProvider = ({ children }: { children: ReactNode }) => {
  function emitCustomEvent(type: string, { action }: CustomEventOptions) {
    const customEvent = new CustomEvent<CustomEventDetail>(action, {
      detail: {
        name: type,
      },
    });
    window.dispatchEvent(customEvent);
  }

  return <EventContext.Provider value={{ emitCustomEvent }}>{children}</EventContext.Provider>;
};
