import { createContext, Dispatch, ReactNode, useEffect, useReducer, useState } from 'react';

import {
  FeedbackAction,
  init,
  INIT_SESSION_STORAGE,
  initialState,
  reducer,
  SessionMap,
} from 'reducers/feedback-reducer';

interface IFeedbackContext {
  state: SessionMap;
  dispatch: Dispatch<FeedbackAction>;
  show: boolean;
  setShow: Dispatch<boolean>;
}

export const FeedbackContext = createContext<IFeedbackContext | undefined>(undefined);

export const FeedbackContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState, init);
  const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch({ type: INIT_SESSION_STORAGE });
  }, []);

  return <FeedbackContext.Provider value={{ state, dispatch, show, setShow }}>{children}</FeedbackContext.Provider>;
};
