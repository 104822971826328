import {
  createContext,
  FC,
  PropsWithChildren,
  Reducer,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { isAxiosError } from 'axios';
import { AuthAction, AuthContext as AuthContextType, AuthState } from 'interfaces/AuthContext';
import { Locale } from 'interfaces/Locale';
import { GuestPayload, LoginPayload } from 'interfaces/LoginPayload';
import { NavItem } from 'interfaces/NavTypes';
import { Unit } from 'interfaces/Unit';
import { Account } from 'interfaces/user/Account';

import { MyToverAPI } from 'api/MyToverAPI';
import {
  GET_ACCOUNT,
  GET_INVITE_OPTIONS,
  GET_MENU,
  GET_UNITS,
  GET_USERS,
  init,
  initialState,
  reducer,
  RESET,
  SET_LANGUAGE,
} from 'reducers/auth-reducer';
import { setUserId } from 'utils/pushDataLayer';
import { ServerSideFilter } from 'components/mytover/invite/UnitUserFilterSection';

import { useGlobal } from './GlobalContext';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<AuthState, AuthAction>, typeof initialState>(
    reducer,
    initialState,
    init,
  );
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [determined, setDetermined] = useState(false);
  const permissions = useMemo(() => state.account?.permissions || [], [state.account]);
  const roles = useMemo(() => state.account?.roles || [], [state.account]);
  let {
    state: { languages },
  } = useGlobal();

  const hasRole = (role: string) => roles.some((userRole) => role === userRole);

  const hasPermission = (requestedPermission: string, checkedPermissions: string[] = permissions) => {
    // Split into domains for further check
    const requestedPermissionDomains = requestedPermission.split('.');

    // Iterate through all user's permissions
    for (const permission of checkedPermissions) {
      // Break down permissions into domains one by one, so they look like ['manage', 'user', 'units']
      const currentPermissionDomains = permission.split('.');

      // Walk through the requested permissions path if user has it
      for (let index = 0; index < requestedPermissionDomains.length; index++) {
        const domain1 = currentPermissionDomains[index];
        const domain2 = requestedPermissionDomains[index];

        // If one domain is missing during check, at least one permission path has ended, so the permission is invalid
        if (!domain1 || !domain2) {
          break;
        }
        // If user has a wildcard permission (*), we found a valid permission
        if (domain1 === '*') {
          return true;
        }
        // If the domains are matching on the same index, it's still a valid permission, continuing to search
        if (domain1 === domain2) {
          // Domains are matching on the index, and there's no further domain to check, we found a valid permission
          if (index === requestedPermissionDomains.length - 1) {
            return true;
          } else {
            continue;
          }
        } else {
          break;
        }
      }
    }

    return false;
  };

  const hasUnitPermission = (requestedPermission: string, unit: Unit) =>
    unit.permissions ? hasPermission(requestedPermission, unit.permissions) : false;

  const can = (requestedPermission: string | string[]) => {
    if (Array.isArray(requestedPermission)) {
      const matchingPermissions = requestedPermission.map((p) => hasPermission(p));
      return matchingPermissions.every((value) => value === true);
    } else {
      return hasPermission(requestedPermission);
    }
  };

  const getMenu = async () => {
    try {
      const {
        status,
        parsed: { data },
      } = await MyToverAPI.getMenu();

      if (status) dispatch({ type: GET_MENU, menu: data });
    } catch (error) {
      console.log(error);
    }
  };

  const signin = async (payload: LoginPayload) => {
    const { status, errors } = await MyToverAPI.signin(payload);
    if (!status) throw Error(errors.message);
    await getAccount();
  };

  const signInAsGuest = async (payload: GuestPayload) => {
    const { status, errors } = await MyToverAPI.signInAsGuest(payload);
    if (!status) throw Error(errors.message);
    await getAccount();
  };

  const signout = async () => {
    const { status, errors } = await MyToverAPI.signout();
    if (!status) throw Error(errors.message);
    setUserId(null);
    dispatch({ type: RESET });
  };

  const getAccount = async () => {
    try {
      const { status, parsed, errors } = await MyToverAPI.getAccountInfo();

      if (status) {
        const { data: account }: { data: Account } = parsed;
        if (account) {
          setUserId(account.id);
          dispatch({ type: GET_ACCOUNT, account });
          if (account.language) {
            //TODO: do some wait for global language response?
            //For now, if global languages not completed, load it here too:
            if (languages.length === 0) {
              await MyToverAPI.getLocales().then((r) => (languages = r.parsed.data));
            }

            const found = languages.find((l: Locale) => l.code === account.language);

            if (found) setLocale(found);
          } else {
            console.log('no language');
          }
        }
      } else {
        console.log(errors);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDetermined(true);
    }
  };

  const getUsers = async (filter: ServerSideFilter = { page: 1, perPage: 10000 }) => {
    const { errors, parsed } = await MyToverAPI.getRelatedUsers(filter);
    if (errors) throw Error(errors);
    const users = parsed.data;
    dispatch({ type: GET_USERS, users });
  };

  const getUnits = useCallback(async (filter: ServerSideFilter = { page: 1, perPage: 50 }) => {
    try {
      const {
        parsed: { data: units },
      } = await MyToverAPI.getUnits(filter);
      dispatch({ type: GET_UNITS, units });
    } catch (error) {
      if (isAxiosError(error)) throw Error(error.message);
    }
  }, []);

  const getInviteOptions = async () => {
    const { errors, parsed } = await MyToverAPI.getUserInviteOptions();
    if (errors) throw Error(errors);
    const inviteOptions = parsed.data;
    dispatch({ type: GET_INVITE_OPTIONS, inviteOptions });
  };

  const setLocale = async (selected: Locale) => {
    const { parsed } = await MyToverAPI.setLocale(selected);
    if (parsed) {
      i18n.changeLanguage(selected.code);
      dispatch({ type: SET_LANGUAGE, language: selected.code });

      return Promise.resolve(parsed);
    }
  };

  const menu = useMemo(() => {
    // Checks if at least one of the permission allows to see the menu item
    // const isPermitted = (item: NavItem) => (!item.permissions ? true : item.permissions.some(hasPermission));
    const isPermitted = (item: NavItem) =>
      item.permissions ? item.permissions.some((value) => hasPermission(value)) : true;

    // Traverses the menu and only selects those items that are permitted for
    // the user to see
    const getPermittedMenuItems = (menu: NavItem[] = []) => {
      const permittedMenu = [];

      for (const menuItem of menu) {
        // If item or its submenu items are restricted to see, do not include
        // in the menu
        if (!isPermitted(menuItem) || (Array.isArray(menuItem.children) && menuItem.children.length === 0)) continue;

        // If item is not restricted for the user and should not have submenu
        // items, display it in the menu
        if (!Array.isArray(menuItem.children)) {
          permittedMenu.push(menuItem);
          continue;
        }

        // Call the function on its submenus as well
        const childMenuItems: NavItem[] = getPermittedMenuItems(menuItem.children);

        permittedMenu.push({ ...menuItem, children: childMenuItems });
      }

      return permittedMenu;
    };

    return getPermittedMenuItems(state.menu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.menu, state.account]);

  const value = useMemo(
    () => ({
      state,
      menu,
      loading,
      determined,
      dispatch,
      getMenu,
      signin,
      signInAsGuest,
      signout,
      getAccount,
      getUsers,
      getUnits,
      getInviteOptions,
      setLoading,
      setLocale,
      can,
      hasRole,
      hasUnitPermission,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state, loading, determined],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) throw Error('useAuth: The hook is used outside its context.');

  return authContext;
};
