import { FC, PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from 'contexts/AuthContext';

type RequireAuthProps = PropsWithChildren<{
  permission?: string;
}>;

// @ts-ignore Type checker wrongfully says children can be undefined
export const RequireAuth: FC<RequireAuthProps> = ({ children, permission }) => {
  const auth = useAuth();
  const location = useLocation();

  // Wait for the user information to return before we render the page
  if (!auth.determined) return null;

  // Redirect user back to the sign in page if not logged in, but is required
  if (!auth.state.account) {
    return <Navigate to="/signin" state={{ from: location, error: { status: 401, variant: 'warning' } }} replace />;
  }

  // Redirect user back to the home page if tried to access forbidden page
  if (permission && !auth.can(permission)) {
    if (location.pathname.includes('/lab')) {
      return <Navigate to="/lab/no-access" />;
    }
    return <Navigate to="/" state={{ from: location, error: { status: 403, variant: 'error' } }} replace />;
  }

  return children;
};
