import { FC, useState } from 'react';
import { NavItem } from 'interfaces/NavTypes';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { getRouteProps } from 'utils/getRouteProps';

interface HeaderMenuItemProps {
  item: NavItem;
}

export const HeaderMenuItem: FC<HeaderMenuItemProps> = ({ item }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        ...(open && {
          bgcolor: 'primary.main',
          borderColor: 'primary.main',
          borderTopLeftRadius: 10,
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
          borderTopRightRadius: 24,
          color: 'secondary.main',
        }),
      }}
    >
      <Button
        id={`${item.title}-button`}
        aria-controls={`${item.title}-menu`}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        endIcon={open ? <ExpandLess /> : <ExpandMore />}
        onClick={handleClick}
        variant="outlined"
        sx={{
          'border': 'none',
          'borderRadius': 24,
          'borderTopLeftRadius': 10,
          'transition': (theme) =>
            theme.transitions.create(['border-radius', 'background-color'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.shortest,
            }),
          '&:hover': {
            border: 'none',
            textDecoration: (theme) => `underline 2px solid ${theme.palette.primary.main}`,
            textUnderlinePosition: 'under',
          },
        }}
      >
        {item.title}
      </Button>

      <Menu
        id={`${item.title}-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock
        elevation={0}
        MenuListProps={{
          'aria-labelledby': `${item.title}-button`,
        }}
        sx={{
          '& .MuiPaper-root': {
            bgcolor: 'primary.main',
            borderRadius: 2.5,
            borderTopLeftRadius: 0,
            minWidth: 200,
          },
        }}
      >
        {item.children?.map((child) => {
          const props = getRouteProps(child);

          return (
            <MenuItem
              disableRipple
              onClick={handleClose}
              sx={{ '&:hover': { backgroundColor: 'primary.light' } }}
              {...props}
            >
              {child.title}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};
