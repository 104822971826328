import { Dispatch, FC, SetStateAction } from 'react';
import { Dialog, DialogProps, ModalProps } from '@mui/material';

interface DialogContainerProps extends Omit<DialogProps, 'onClose'> {
  onClose: Dispatch<SetStateAction<boolean>>;
  closeOnBackdropClick?: boolean;
}

export const DialogContainer: FC<DialogContainerProps> = ({ children, onClose, closeOnBackdropClick, ...props }) => {
  const handleClose: ModalProps['onClose'] = (_, reason) => {
    if (reason === 'backdropClick' && !closeOnBackdropClick) return;
    onClose(false);
  };

  return (
    <Dialog onClose={handleClose} {...props}>
      {children}
    </Dialog>
  );
};
