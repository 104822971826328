type ContentType = 'theme' | 'section' | 'set' | 'game';

type FormID = 'create-set' | 'library-sync' | 'creations-sync';
type FormEvent = 'form_submit' | 'form_stop';

type FormDataObject = {
  to_tovertafel?: boolean;
  create_step?: number; // TS problems with Math and numeric literals (range)
};

/**
 * If you push a variable with the same name as an existing variable to the data layer,
 * the existing value will be overwritten by the new value
 */
type DataLayerObject = {
  event?: string;
  [key: string]: any;
};

declare const window: Window & { dataLayer: Record<string, unknown>[] };

const pushDataLayer = (object: DataLayerObject) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(object);
};

export const setUserId = (id: string | null) => {
  pushDataLayer({
    event: 'login',
    user_id: id,
  });
};

export const selectContent = (content_type: ContentType, item_id: string) => {
  pushDataLayer({
    event: 'select_content',
    content_type,
    item_id,
  });
};

export const trackForm = (event: FormEvent, id: FormID, data: Partial<FormDataObject>) => {
  pushDataLayer({
    event,
    form_id: id,
    form_data: data,
  });
};

export const trackError = (type: string, message: string) => {
  pushDataLayer({
    event: 'form_error',
    error_type: type,
    error_message: message,
  });
};
