import { FC } from 'react';
import { Card, CardProps } from '@mui/material';

export const CardContainer: FC<CardProps> = ({ children, sx, ...props }) => (
  <Card
    {...props}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      border: (theme) => `1px solid ${theme.palette.grey[200]}`,
      ...sx,
    }}
  >
    {children}
  </Card>
);
