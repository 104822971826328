import { AuthAction, AuthState } from 'interfaces/AuthContext';
import { NavItem } from 'interfaces/NavTypes';
import { Unit } from 'interfaces/Unit';
import { Account } from 'interfaces/user/Account';
import { User } from 'interfaces/user/User';

export const GET_MENU = 'GET_MENU';
export const GET_ACCOUNT = 'GET_ACCOUNT';
export const GET_USERS = 'GET_USERS';
export const GET_UNITS = 'GET_UNITS';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const GET_INVITE_OPTIONS = 'GET_INVITE_OPTIONS';
export const RESET = 'RESET';

export const initialState: AuthState = {
  menu: undefined,
  account: undefined,
  users: undefined,
  units: undefined,
};

export const init = (initialState: AuthState) => initialState;

export const reducer = (state: any, action: AuthAction) => {
  switch (action.type) {
    case GET_MENU:
      return getMenu(state, action.menu);
    case GET_ACCOUNT:
      return getAccount(state, action.account);
    case GET_USERS:
      return getUsers(state, action.users);
    case GET_UNITS:
      return getUnits(state, action.units);
    case SET_LANGUAGE:
      return action.language ? setLanguage(state, action.language) : state;
    case GET_INVITE_OPTIONS:
      return getInviteOptions(state, action.inviteOptions);
    case RESET:
      return init(initialState);
    default:
      throw new Error();
  }
};

const getMenu = (state: AuthState, menu?: NavItem[]) => {
  return { ...state, menu };
};

const getAccount = (state: AuthState, account?: Account) => {
  return { ...state, account };
};

const getUsers = (state: AuthState, users?: User[]) => {
  return { ...state, users };
};

const getUnits = (state: AuthState, units?: Unit[]) => {
  return { ...state, units };
};

const setLanguage = (state: AuthState, language?: string) => {
  state.account = { ...state.account, language } as Account;
  return state;
};

const getInviteOptions = (state: AuthState, inviteOptions?: object) => {
  return { ...state, inviteOptions };
};
