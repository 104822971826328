import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, Link, Typography } from '@mui/material';

import { useAuth } from 'contexts/AuthContext';
import { DefaultLayout } from 'components/page/layouts/DefaultLayout';
import PageHeaderSection from 'components/page/PageHeaderSection';
import PageSectionContainer from 'components/page/PageSectionContainer';
import { PageSectionDivider } from 'components/page/PageSectionDivider';
import ContactCard from 'components/service-center/ContactCard';

const Forbidden: FC = () => {
  const { t } = useTranslation();
  const {
    state: { account },
  } = useAuth();

  return (
    <DefaultLayout>
      <PageHeaderSection title={t('lab.get-access.title')} />

      <PageSectionContainer>
        <Typography gutterBottom>{t('lab.get-access.description')}</Typography>

        {!account && (
          <Box sx={{ mt: 2, mb: 4 }}>
            <Typography>{t('forbidden.description')}</Typography>
            <Button sx={{ mt: 2 }} color="secondary" component={RouterLink} to="/request-invite">
              {t('opl.dialog.actions.request')}
            </Button>
          </Box>
        )}

        <Trans
          i18nKey="forbidden.link.text"
          components={[<Link underline="always" href={t('forbidden.link.href')} target="_blank" />]}
          parent="p"
        />
      </PageSectionContainer>

      <PageSectionDivider />

      <PageSectionContainer>
        <ContactCard />
      </PageSectionContainer>
    </DefaultLayout>
  );
};

export default Forbidden;
