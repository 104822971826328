import { FC, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { FORM_ERROR, FormApi } from 'final-form';
import { makeRequired, makeValidate } from 'mui-rff';
import * as Yup from 'yup';
import { Button, DialogContentText, TextField, Typography } from '@mui/material';

import { MyToverAPI } from 'api/MyToverAPI';
import CenteredCircularProgress from 'components/page/CenteredCircularProgress';
import { Dialog } from 'components/shared/Dialog';

import FeedbackButton from './FeedbackButton';

export interface UserFeedbackFormData {
  subject: string;
  message: string;
}

export const UserFeedback: FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<string | undefined>();

  const openFeedbackDialog = () => {
    setOpen(true);
  };

  const closeFeedbackDialog = async () => {
    setOpen(false);
    setMessage('');
    setSubmitted(undefined);
  };

  const onSubmit = async (values: UserFeedbackFormData, form: FormApi<UserFeedbackFormData, { message: any }>) => {
    setSubmitting(true);

    try {
      const { status, parsed, errors } = await MyToverAPI.postFeedback(values);
      if (errors) {
        return { [FORM_ERROR]: t('error.data') };
      }
      if (status) {
        setSubmitted(t(parsed.message));
      }
    } catch (error) {
      return { [FORM_ERROR]: t('error.unknown') };
    } finally {
      setTimeout(form.reset);
      setSubmitting(false);
    }
  };

  const schema = Yup.object({
    message: Yup.string().required(t('form.required')),
  });

  const validate = makeValidate(schema);
  const required = makeRequired(schema);

  return (
    <>
      <FeedbackButton openDialog={openFeedbackDialog} />

      <Dialog.Container open={open} onClose={closeFeedbackDialog} closeOnBackdropClick>
        <Dialog.Title>{t('feedback.form.title')}</Dialog.Title>
        {!submitting && !submitted ? (
          <>
            <Dialog.Content>
              <Form
                onSubmit={onSubmit}
                initialValues={{ message }}
                validate={validate}
                render={({ handleSubmit }) => (
                  <form id="feedback-form" onSubmit={handleSubmit} noValidate>
                    <Typography sx={{ mb: 2 }}>{t('feedback.form.message')}</Typography>
                    <TextField
                      fullWidth
                      id="outlined-textarea"
                      multiline
                      rows={5}
                      value={message}
                      placeholder={t('feedback.form.placeholder.message')}
                      required={required.message}
                      onChange={(event) => setMessage(event.target.value)}
                    />
                  </form>
                )}
              />
            </Dialog.Content>
            <Dialog.Actions>
              <Button type="submit" form="feedback-form" disabled={submitting || !message}>
                {t('feedback.form.submit')}
              </Button>
            </Dialog.Actions>
          </>
        ) : (
          <Dialog.Content>
            {submitting ? (
              <CenteredCircularProgress />
            ) : (
              <DialogContentText>{t('feedback.form.success')}</DialogContentText>
            )}
          </Dialog.Content>
        )}
      </Dialog.Container>
    </>
  );
};

export default UserFeedback;
