import { FC } from 'react';
import { CardHeader as MuiCardHeader, CardHeaderProps } from '@mui/material';

export const CardHeader: FC<CardHeaderProps> = (props) => (
  <MuiCardHeader
    {...props}
    subheaderTypographyProps={{
      sx: { mb: 2 },
    }}
    sx={{
      'display': 'flex',
      'flexDirection': 'column',
      'alignItems': 'baseline',
      'p': 4,
      'pb': 1,
      '.MuiCardHeader-avatar': { mr: 0, mb: 2 },
      '.MuiCardHeader-content': {
        'display': 'flex',
        'flexDirection': 'column-reverse',
        '> span': {
          wordBreak: 'break-word',
        },
      },
      ...props.sx,
    }}
    titleTypographyProps={{
      variant: 'h3',
      ...props.titleTypographyProps,
    }}
  />
);
