import { forwardRef } from 'react';
import { Container, ContainerProps } from '@mui/material';

interface ContentWrapperProps extends ContainerProps {
  component?: any;
  display?: string;
  label?: string;
}

const PageSectionContainer = (
  { children, className, component = 'section', display, label, maxWidth = 'lg', sx }: ContentWrapperProps,
  ref?: any,
) => {
  return (
    <Container
      aria-label={label}
      className={className}
      component={component}
      disableGutters
      maxWidth={maxWidth}
      ref={ref}
      sx={{
        py: 7,
        display,
        gridTemplateColumns: 'repeat(12, 1fr)',
        gap: {
          xs: 1,
          sm: 2,
        },
        paddingInline: {
          xs: 2,
          sm: 4,
        },
        ...sx,
      }}
    >
      {children}
    </Container>
  );
};

export default forwardRef(PageSectionContainer);
