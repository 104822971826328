import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Fallback } from 'router/Fallback';
import { RequireAuth } from 'router/RequireAuth';

import { StudioContextProvider } from '../../contexts/StudioContext';

const ManagementContainer = lazy(() => import('pages/manage/ManagementContainer'));
const UserOverview = lazy(() => import('pages/manage/users/UserOverview'));
const UserDetail = lazy(() => import('pages/manage/users/UserDetail'));
const UnitOverview = lazy(() => import('pages/manage/units/UnitOverview'));
const UnitDetail = lazy(() => import('pages/manage/units/UnitDetail'));
const PartnerOverview = lazy(() => import('pages/manage/partners/PartnerOverview'));
const PartnerDetail = lazy(() => import('pages/manage/partners/PartnerDetail'));
const KpiOverview = lazy(() => import('pages/manage/kpi/KpiOverview'));
const TranslationsPage = lazy(() => import('pages/manage/Translations'));
const ReportOverview = lazy(() => import('pages/manage/reports/ReportOverview'));
const ToverSetOverview = lazy(() => import('pages/manage/toverSets/ToverSetOverview'));
const ToverSetDetail = lazy(() => import('pages/manage/toverSets/ToverSetDetail'));
const ArticleOverview = lazy(() => import('pages/manage/articles/ArticleOverview'));
const ArticleDetail = lazy(() => import('pages/manage/articles/ArticleDetail'));

export const ManagementRoutes = [
  {
    path: 'manage',
    element: (
      <Suspense fallback={<Fallback />}>
        <RequireAuth permission="manage">
          <StudioContextProvider>
            <ManagementContainer />
          </StudioContextProvider>
        </RequireAuth>
      </Suspense>
    ),
    children: [
      {
        path: 'kpi',
        element: (
          <Suspense fallback={<Fallback />}>
            <RequireAuth permission="view.kpi">
              <KpiOverview />
            </RequireAuth>
          </Suspense>
        ),
      },
      {
        path: 'units',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<Fallback />}>
                <UnitOverview />
              </Suspense>
            ),
          },
          {
            path: ':serial',
            element: (
              <Suspense fallback={<Fallback />}>
                <UnitDetail />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'users',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<Fallback />}>
                <RequireAuth permission="manage.unit.users">
                  <UserOverview />
                </RequireAuth>
              </Suspense>
            ),
          },
          {
            path: ':id',
            element: (
              <Suspense fallback={<Fallback />}>
                <RequireAuth permission="view.user.details">
                  <UserDetail />
                </RequireAuth>
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'partners',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<Fallback />}>
                <RequireAuth permission="view.partner.index">
                  <PartnerOverview />
                </RequireAuth>
              </Suspense>
            ),
          },
          {
            path: ':id',
            element: (
              <Suspense fallback={<Fallback />}>
                <RequireAuth permission="view.partner.info">
                  <PartnerDetail />
                </RequireAuth>
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'translations',
        element: (
          <Suspense fallback={<Fallback />}>
            <RequireAuth permission="manage.translations">
              <TranslationsPage />
            </RequireAuth>
          </Suspense>
        ),
      },
      {
        path: 'reports',
        element: (
          <Suspense fallback={<Fallback />}>
            <RequireAuth permission="download.reports">
              <ReportOverview />
            </RequireAuth>
          </Suspense>
        ),
      },
      {
        path: 'tover-sets',
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<Fallback />}>
                <RequireAuth permission="manage.studio.tover-sets">
                  <ToverSetOverview />
                </RequireAuth>
              </Suspense>
            ),
          },
          {
            path: ':id',
            element: (
              <Suspense fallback={<Fallback />}>
                <RequireAuth permission="studio.sets.tover.update">
                  <ToverSetDetail />
                </RequireAuth>
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'articles',
        element: (
          <Suspense fallback={<Fallback />}>
            <RequireAuth permission="manage.articles">
              <Outlet />
            </RequireAuth>
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<Fallback />}>
                <ArticleOverview />
              </Suspense>
            ),
          },
          {
            path: ':id',
            element: (
              <Suspense fallback={<Fallback />}>
                <ArticleDetail />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
];
