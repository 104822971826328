import { FC, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppBar, Box, IconButton, Link, Theme, Toolbar, useMediaQuery, useScrollTrigger } from '@mui/material';
import { Menu as HamburgerIcon } from '@mui/icons-material';

import { useAuth } from 'contexts/AuthContext';
import { DrawerMenu } from 'components/app/DrawerMenu';
import { RegisterButton } from 'components/buttons/RegisterButton';
import { SignInButton } from 'components/buttons/SignInButton';
import { MyToverLogo } from 'components/shared/MyToverLogo';

import { HeaderAvatarMenu, HeaderLanguageSelector, HeaderLink, HeaderMenuItem } from './components';

export const Header: FC = () => {
  const auth = useAuth();
  const location = useLocation();
  const { i18n } = useTranslation();
  const onDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [showShadow, setShowShadow] = useState(false);
  const [isDrawerMenuOpen, setDrawerMenuOpen] = useState(false);
  const isActivationPage = useMemo(() => location.pathname.match('/activate'), [location]);
  const isGuest = auth.hasRole('guest');
  const isLoggedIn = !!auth.state.account;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  useEffect(() => {
    if (auth.determined) auth.getMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.determined, auth.state.account, i18n.language]);

  useEffect(() => {
    setShowShadow(trigger);
  }, [trigger]);

  const toggleDrawer = () => {
    setDrawerMenuOpen((value) => !value);
  };

  return (
    <AppBar
      color="secondary"
      position="fixed"
      elevation={showShadow ? 4 : 0}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar aria-label="Primary menu" component="nav">
        <Link color="inherit" component={RouterLink} to="/">
          <MyToverLogo />
        </Link>

        {!isActivationPage ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              flexGrow: 1,
            }}
          >
            {onDesktop && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {auth.menu.map((item, index) => {
                  return item.children?.length ? (
                    <HeaderMenuItem key={index} item={item} />
                  ) : (
                    <HeaderLink key={index} item={item} />
                  );
                })}
              </Box>
            )}

            {onDesktop && (!isLoggedIn || isGuest) && (
              <>
                <RegisterButton contrast sx={{ mr: 1 }} />
                <SignInButton className="me-2" />
              </>
            )}

            {isLoggedIn && !isGuest ? <HeaderAvatarMenu /> : <HeaderLanguageSelector />}

            {!onDesktop && (
              <>
                <IconButton
                  onClick={toggleDrawer}
                  color="inherit"
                  sx={{
                    '&:hover': {
                      backgroundColor: 'primary.main',
                    },
                  }}
                >
                  <HamburgerIcon />
                </IconButton>
                <DrawerMenu open={isDrawerMenuOpen} toggleDrawer={toggleDrawer} />
              </>
            )}
          </Box>
        ) : null}
      </Toolbar>
    </AppBar>
  );
};
