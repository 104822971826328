import { Dispatch, FC, SetStateAction } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '@mui/material';

import { useAuth } from 'contexts/AuthContext';

interface SignInButtonProps {
  className?: string;
  size?: ButtonProps['size'];
  toggleDrawer?: Dispatch<SetStateAction<boolean>>;
}

export const SignInButton: FC<SignInButtonProps> = ({ size, className, toggleDrawer }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = !!auth.state.account;
  const isLoggedInAsUser = isLoggedIn && !auth.hasRole('guest');

  const handleClick = async () => {
    if (isLoggedInAsUser) {
      await auth.signout();
      navigate('/');
    } else {
      navigate('/signin', { state: { from: location } });
    }
    if (toggleDrawer) toggleDrawer(false);
  };

  return (
    <Button color={isLoggedIn ? 'secondary' : 'primary'} {...{ size, className }} onClick={handleClick}>
      {isLoggedInAsUser ? t('user.signout') : t('user.signin')}
    </Button>
  );
};
