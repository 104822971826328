import { FC } from 'react';
import { CardMedia as MuiCardMedia, CardMediaProps } from '@mui/material';

export const CardMedia: FC<CardMediaProps<'img'>> = ({ sx, ...props }) => (
  <MuiCardMedia
    component="img"
    sx={{
      aspectRatio: '16 / 9',
      minWidth: '50%',
      ...sx,
    }}
    {...props}
  />
);
