import { FC } from 'react';
import { Box, DialogTitle as MuiDialogTitle, DialogTitleProps as MuiDialogTitleProps } from '@mui/material';

interface DialogTitleProps extends MuiDialogTitleProps {
  icon?: any;
}

export const DialogTitle: FC<DialogTitleProps> = ({ children, icon, ...props }) => {
  return (
    <MuiDialogTitle sx={{ 'typography': 'h3', '& svg': { fontSize: '2.5rem' } }} {...props}>
      {icon && (
        <Box pb={2} textAlign="center">
          {icon}
        </Box>
      )}
      {children}
    </MuiDialogTitle>
  );
};
