import { createTheme, darken, darkScrollbar } from '@mui/material';
import { Check, KeyboardArrowDownRounded } from '@mui/icons-material';

let theme = createTheme({
  palette: {
    primary: {
      main: '#f1be48',
    },
    secondary: {
      main: '#081f2c',
      contrastText: 'white',
    },
  },
  mixins: {
    toolbar: {
      minHeight: 80,
    },
  },
  typography: {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontSize: 16,
    fontWeightMedium: 600,
    h1: {
      fontFamily: "'Source Serif Pro', serif",
      fontWeight: 700,
      fontSize: '2.5rem',
      lineHeight: 1.2,
    },
    h2: {
      fontFamily: "'Source Serif Pro', serif",
      fontWeight: 700,
      fontSize: '2rem',
      letterSpacing: 0.25,
      lineHeight: 1.2,
    },
    h3: {
      fontFamily: "'Source Serif Pro', serif",
      fontWeight: 700,
      fontSize: '1.75rem',
      letterSpacing: 0.25,
      lineHeight: 1.2,
    },
    h4: {
      fontFamily: "'Source Serif Pro', serif",
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: 1.2,
    },
    h5: {
      fontFamily: "'Source Serif Pro', serif",
      fontWeight: 600,
      fontSize: '1.25rem',
      lineHeight: 1.2,
    },
    h6: {
      fontFamily: "'Source Serif Pro', serif",
      fontWeight: 600,
      fontSize: '1.25rem',
      letterSpacing: 0.15,
      lineHeight: 1.2,
    },
    subtitle1: {
      fontSize: '1.25rem',
      fontWeight: 300,
      letterSpacing: 0.15,
      lineHeight: 1.2,
    },
    subtitle2: {
      fontSize: '1.125rem',
      fontWeight: 300,
      letterSpacing: 0.2,
      lineHeight: 1.2,
    },
    body1: {
      fontSize: '1.125rem',
      letterSpacing: 0.25,
      lineHeight: 1.5,
    },
    body2: {
      letterSpacing: 0.25,
      lineHeight: 1.5,
    },
    button: {
      fontSize: '1.125rem',
      fontWeight: 600,
      textTransform: 'none',
      lineHeight: 1.2,
    },
    caption: {
      fontSize: '0.813rem',
      opacity: 0.6,
      letterSpacing: 0.4,
      lineHeight: 1.2,
    },
    overline: {
      fontSize: '0.688rem',
      opacity: 0.6,
      textTransform: 'uppercase',
      letterSpacing: 1,
      lineHeight: 1.2,
    },
  },
});

theme = createTheme(theme, {
  palette: {
    primary: {
      contrastText: theme.palette.secondary.main,
    },
    secondary: {
      contrastText: theme.palette.common.white,
    },
  },
  typography: {
    h1: {
      color: theme.palette.secondary.main,
      [theme.breakpoints.up('md')]: {
        fontSize: '3rem',
      },
    },
    h2: {
      color: theme.palette.secondary.main,
      [theme.breakpoints.up('md')]: {
        fontSize: '2.5rem',
      },
    },
    h3: {
      color: theme.palette.secondary.main,
      [theme.breakpoints.up('md')]: {
        fontSize: '2rem',
      },
    },
    h4: {
      color: theme.palette.secondary.main,
      [theme.breakpoints.up('md')]: {
        fontSize: '1.75rem',
      },
    },
    h5: {
      color: theme.palette.secondary.main,
      [theme.breakpoints.up('md')]: {
        fontSize: '1.5rem',
      },
    },
    h6: {
      color: theme.palette.secondary.main,
    },
    subtitle1: {
      color: theme.palette.secondary.main,
    },
    subtitle2: {
      color: theme.palette.secondary.main,
    },
    caption: {
      color: theme.palette.text.secondary,
    },
    overline: {
      color: theme.palette.text.secondary,
    },
  },
  components: {
    MuiAvatar: {
      defaultProps: {
        variant: 'rounded',
      },
      styleOverrides: {
        colorDefault: {
          color: theme.palette.grey['A700'],
          backgroundColor: theme.palette.grey['A200'],
        },
        fallback: {
          width: '50%',
          height: '50%',
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          color: theme.palette.secondary.main,
          fontSize: '.8rem',
          padding: theme.spacing(3),
        },
        li: {
          '& a:not([class])': {
            'color': theme.palette.secondary.main,
            'borderBottom': 'none',
            'fontWeight': 400,
            '&:hover': {
              borderBottom: 'none',
            },
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          'borderRadius': 24,
          'borderTopRightRadius': 10,
          'borderColor': 'transparent',
          'borderStyle': 'solid',
          'borderWidth': 2,
          'paddingBlock': theme.spacing(0.75),
          'paddingInline': theme.spacing(2),
          '&:hover': { borderWidth: 2 },
        },
        text: {
          'color': theme.palette.secondary.main,
          '&:hover': {
            backgroundColor: theme.palette.grey['A200'],
          },
        },
        contained: {
          '&.Mui-disabled': {
            borderColor: 'transparent',
          },
        },
        containedInherit: {
          'backgroundColor': theme.palette.common.white,
          '&:hover': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.secondary.main,
          },
        },
        containedPrimary: {
          'borderColor': theme.palette.primary.main,
          '&:hover': {
            backgroundColor: theme.palette.common.white,
            borderColor: theme.palette.secondary.main,
          },
        },
        containedSecondary: {
          'borderColor': theme.palette.secondary.main,
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
          },
        },
        outlined: {
          'color': 'inherit',
          'borderColor': theme.palette.secondary.main,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
            borderColor: theme.palette.secondary.main,
          },
        },
        sizeLarge: {
          paddingBlock: theme.spacing(1.5),
          paddingInline: theme.spacing(4),
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'default',
        disableRipple: true,
        icon: <span> </span>,
        checkedIcon: <Check />,
      },
      styleOverrides: {
        root: {
          'border': `2px solid ${theme.palette.grey[800]}`,
          'borderRadius': 2,
          'height': 24,
          'transitionProperty': 'background-color, border-color',
          'transitionDuration': '0.2s',
          'transitionTimingFunction': 'ease',
          'transitionDelay': '0s',
          'width': 24,
          '&:hover, &:focus': {
            backgroundColor: theme.palette.common.white,
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: theme.palette.mode === 'dark' ? darkScrollbar() : null,
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          'overflow': 'hidden',
          'fontSize': '1rem',
          '& .MuiDataGrid-columnHeader--moving': {
            backgroundColor: theme.palette.primary.light,
          },
          '& .MuiDataGrid-menuIcon': {
            width: 'auto',
            visibility: 'visible',
          },
          '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
        },
        columnHeaders: {
          borderRadius: 0,
          backgroundColor: theme.palette.grey[50],
        },
        iconSeparator: {
          display: 'none',
        },
        toolbarContainer: {
          'backgroundColor': theme.palette.grey[50],
          '& .MuiInput-root:hover:not(.Mui-disabled):before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          },
        },
        window: {
          overflowX: 'scroll',
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
        maxWidth: 'sm',
      },
      styleOverrides: {
        paper: {
          borderRadius: 20,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          'padding': theme.spacing(4),
          'paddingTop': 0,
          '>:not(:last-child)': {
            marginRight: theme.spacing(1),
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingInline: theme.spacing(4),
          paddingBlock: theme.spacing(2),
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          marginBottom: theme.spacing(2),
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: theme.spacing(4),
          paddingBottom: theme.spacing(2),
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: '100%',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          'fontFamily': theme.typography.fontFamily,
          'fontSize': theme.typography.htmlFontSize,
          'fontStyle': 'normal',
          'fontWeight': 400,
          '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.54)',
          },
          'marginLeft': 0,
          'marginRight': 0,
          'padding': '0 1.2rem',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorSecondary: {
          'backgroundColor': theme.palette.secondary.main,
          'borderRadius': 40,
          'color': theme.palette.common.white,
          'height': 60,
          'width': 60,
          '&:hover': {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.secondary.main,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.secondary.main,
          fontSize: '1.125rem',
          margin: '.5rem auto',
          padding: '0 1.2rem',
          textAlign: 'left',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        color: 'inherit',
        underline: 'hover',
      },
      styleOverrides: {
        underlineNone: {
          '& span': {
            position: 'relative',
            overflow: 'hidden',
          },
          '& span::before': {
            content: "''",
            position: 'absolute',
            width: 0,
            height: 2,
            bottom: -4,
            left: 0,
            background: 'currentColor',
            transition: 'width 0.25s ease',
          },
          '& span:hover::before': {
            left: 0,
            right: 'auto',
            width: '100%',
          },
        },
        underlineHover: {
          'textDecoration': 'underline',
          'textDecorationColor': 'transparent',
          'textDecorationThickness': 2,
          'textUnderlinePosition': 'under',
          'transition': 'text-decoration .25s ease',
          '&:hover': {
            textDecorationThickness: 2,
          },
        },
        underlineAlways: {
          'fontWeight': 'bold',
          'textUnderlinePosition': 'under',
          '&:hover': {
            cursor: 'pointer',
            textDecorationThickness: 2,
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          'borderRadius': 4,
          '&.Mui-selected': {
            'backgroundColor': theme.palette.primary.main,
            '&:hover': {
              backgroundColor: darken(theme.palette.primary.main, 0.04),
            },
          },
        },
        gutters: {
          paddingTop: 1,
          paddingBottom: 1,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          '& .MuiTypography-root': {
            fontSize: '1rem',
            fontWeight: 600,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          'backgroundColor': theme.palette.common.white,
          'borderRadius': 32,
          'overflow': 'hidden',
          '& input:valid+fieldset::after': {
            top: 15,
            width: 16,
            height: 16,
          },
          '& input[aria-invalid="true"]+fieldset::after': {
            top: 15,
            width: 16,
            height: 16,
          },
        },
        input: {
          paddingBlock: 16,
          paddingInline: 20,
        },
        notchedOutline: {
          top: -4,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          marginLeft: -60,
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: KeyboardArrowDownRounded,
      },
      styleOverrides: {
        icon: {
          top: '50% - 10px',
          color: theme.palette.secondary.main,
        },
        iconOutlined: {
          right: '1.3rem',
          transform: 'scale(2)',
          width: 20,
        },
        outlined: {
          'border': `2px solid ${theme.palette.secondary.main}`,
          'borderRadius': 40,
          '&:focus': {
            backgroundColor: theme.palette.common.white,
            border: `2px solid ${theme.palette.common.white}`,
            borderRadius: 40,
          },
          '&.MuiSelect-outlined': {
            paddingRight: 42,
          },
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          paddingInline: 0,
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          [theme.breakpoints.only('xs')]: {
            marginLeft: '1rem',
          },
          marginLeft: '2rem',
          marginRight: '2rem',
        },
        line: {
          borderColor: theme.palette.grey[300],
        },
      },
    },
    MuiStepContent: {
      styleOverrides: {
        root: {
          borderLeftColor: theme.palette.grey[300],
          marginLeft: '1rem',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          ...theme.typography.button,
          width: '2rem',
          height: '2rem',
          color: theme.palette.grey[300],
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        fontSize: 'small',
      },
      styleOverrides: {
        fontSizeSmall: {
          fontSize: '1.5rem',
        },
        fontSizeLarge: {
          fontSize: '3.5rem',
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          'fontWeight': 'normal',
          '&.Mui-focusVisible': {
            backgroundColor: theme.palette.primary.light,
          },
          '&.Mui-selected': {
            color: theme.palette.secondary.main,
            fontWeight: 600,
          },
          '&.Mui-disabled': {
            color: theme.palette.action.disabled,
          },
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          paddingInline: 0,
          minHeight: theme.spacing(30),
        },
      },
    },
    MuiTable: {
      // Overrides base.scss
      styleOverrides: {
        root: {
          '& thead': {
            '& th, td': {
              backgroundColor: theme.palette.common.white,
            },
          },
          '& tbody tr': {
            background: theme.palette.common.white,
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        displayedRows: {
          margin: 0,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          '& fieldset': {
            border: `2px solid ${theme.palette.secondary.main}`,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.secondary.main,
        },
      },
    },
  },
});

export default theme;
