import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ButtonBase, Grid, Paper, styled, Theme, Typography, useMediaQuery } from '@mui/material';

import { stringToSlug } from 'utils/slugify';

interface InspirationCard {
  title: string;
  category: string;
  path: string;
}

const StyledImage = styled('img')({
  width: 200,
  height: 200,
  marginLeft: 'auto',
  marginRight: 'auto',
});

export const NotFound: FC = () => {
  const { t } = useTranslation();

  const slugOptions = { lower: true, strict: true };

  const cards: InspirationCard[] = [
    {
      title: t('notFound.card.module1'),
      category: t('common.category.introduction'),
      path: `/articles/${stringToSlug(t('notFound.card.module1'), slugOptions)}`,
    },
    {
      title: t('notFound.card.instruction'),
      category: t('common.category.videos'),
      path: `/articles/${stringToSlug(t('notFound.card.instruction'), slugOptions)}`,
    },
    {
      title: t('notFound.card.faq'),
      category: t('common.category.contact'),
      path: `/categories/${stringToSlug(t('notFound.card.faq'), slugOptions)}`,
    },
    {
      title: t('notFound.card.contact'),
      category: t('common.category.contact'),
      path: `/articles/${stringToSlug(t('notFound.card.contact'), slugOptions)}`,
    },
  ];

  const onMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));

  return (
    <Grid container direction="column" justifyContent="space-between">
      <Grid item container justifyContent="center" textAlign="center" m="auto">
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            textAlign: 'center',
            mx: 2,
            my: 4,
          }}
        >
          <StyledImage src={`${process.env.PUBLIC_URL}/images/puzzle.jpg`} alt="puzzle piece" />
          <Typography variant="h1" gutterBottom sx={{ mt: 4 }}>
            {t('notFound.title')}
          </Typography>
          <Typography variant="subtitle2">{t('notFound.subtitle')}</Typography>
          <Button color="primary" component={RouterLink} fullWidth={!!onMobile} to="/" sx={{ my: 4 }}>
            {t('notFound.button')}
          </Button>
        </Grid>
      </Grid>
      <Grid
        item
        container
        sx={{
          bgcolor: 'grey.100',
          pt: 6,
          pb: 10,
          px: {
            xs: 2,
            sm: 4,
          },
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h2" sx={{ mb: 4 }}>
            {t('notFound.inspiration')}
          </Typography>
        </Grid>

        <Grid item container spacing={2}>
          {cards.map((card, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <ButtonBase component={RouterLink} to={card.path} sx={{ height: '100%', width: '100%' }}>
                <Paper
                  square
                  variant="outlined"
                  sx={{
                    'height': '100%',
                    'width': '100%',
                    'p': 2,
                    '&:hover': { boxShadow: 2 },
                  }}
                >
                  <Typography variant="h4" component="h3" gutterBottom sx={{ overflowWrap: 'break-word' }}>
                    {card.title}
                  </Typography>
                  <Typography>{card.category}</Typography>
                </Paper>
              </ButtonBase>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
