import { DialogActions } from './components/DialogActions';
import { DialogContainer } from './components/DialogContainer';
import { DialogContent } from './components/DialogContent';
import { DialogTitle } from './components/DialogTitle';

export const Dialog = {
  Container: DialogContainer,
  Title: DialogTitle,
  Content: DialogContent,
  Actions: DialogActions,
};
