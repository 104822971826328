import { lazy, Suspense } from 'react';

import { Fallback } from 'router/Fallback';
import { RedirectToHome } from 'router/RedirectToHome';

const HomePage = lazy(() => import('pages/mytover/MyToverHome'));
const SignInPage = lazy(() => import('pages/mytover/SignIn'));
const ResetPasswordPage = lazy(() => import('pages/mytover/PasswordResetCreateNew'));
const ResetPasswordSuccessPage = lazy(() => import('pages/mytover/PasswordResetMailSent'));
const RequestInvitePage = lazy(() => import('pages/mytover/RequestInvite'));
const RequestInviteSuccessPage = lazy(() => import('pages/mytover/RequestInviteSuccess'));
const AcceptInvitePage = lazy(() => import('pages/mytover/AcceptInvite'));
const ActivateAccountPage = lazy(() => import('pages/mytover/ActivateAccount'));
const ArticlePage = lazy(() => import('pages/mytover/ArticleContainer'));
const CategoriesPage = lazy(() => import('pages/mytover/CategoryContainer'));
const ForbiddenPage = lazy(() => import('pages/Forbidden'));

export const RootRoutes = [
  {
    index: true,
    element: (
      <Suspense fallback={<Fallback />}>
        <HomePage />
      </Suspense>
    ),
  },
  {
    path: 'mytover/*',
    element: <RedirectToHome />,
  },
  {
    path: 'signin',
    element: (
      <Suspense fallback={<Fallback />}>
        <SignInPage />
      </Suspense>
    ),
  },
  {
    path: 'user',
    children: [
      {
        path: 'reset-password/:token',
        element: (
          <Suspense fallback={<Fallback />}>
            <ResetPasswordPage />
          </Suspense>
        ),
      },
      {
        path: 'confirm-reset',
        element: (
          <Suspense fallback={<Fallback />}>
            <ResetPasswordSuccessPage />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: 'request-invite',
    element: (
      <Suspense fallback={<Fallback />}>
        <RequestInvitePage />
      </Suspense>
    ),
  },
  {
    path: 'request-success',
    element: (
      <Suspense fallback={<Fallback />}>
        <RequestInviteSuccessPage />
      </Suspense>
    ),
  },
  {
    path: 'accept-invite/:token',
    element: (
      <Suspense fallback={<Fallback />}>
        <AcceptInvitePage />
      </Suspense>
    ),
  },
  {
    path: 'activate/:userId/:token',
    element: (
      <Suspense fallback={<Fallback />}>
        <ActivateAccountPage />
      </Suspense>
    ),
  },
  {
    path: 'articles',
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<Fallback />}>
            <ArticlePage />
          </Suspense>
        ),
      },
      {
        path: ':id/:slug/:locale',
        element: (
          <Suspense fallback={<Fallback />}>
            <ArticlePage />
          </Suspense>
        ),
      },
      {
        path: ':id/:slug',
        element: (
          <Suspense fallback={<Fallback />}>
            <ArticlePage />
          </Suspense>
        ),
      },
      {
        path: ':id',
        element: (
          <Suspense fallback={<Fallback />}>
            <ArticlePage />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: 'pixie',
    element: (
      <Suspense fallback={<Fallback />}>
        <ArticlePage identifier={process.env.REACT_APP_PIXIE_ARTICLE_ID} />
      </Suspense>
    ),
  },
  {
    path: 'categories/:id',
    element: (
      <Suspense fallback={<Fallback />}>
        <CategoriesPage />
      </Suspense>
    ),
  },
  {
    path: '403',
    element: (
      <Suspense fallback={<Fallback />}>
        <ForbiddenPage />
      </Suspense>
    ),
  },
];
