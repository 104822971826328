import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { EULA } from 'interfaces/policies/EndUserLicenseAgreement';
import { useSnackbar } from 'notistack';
import { Alert, Button, DialogContentText, Link } from '@mui/material';
import { Download } from '@mui/icons-material';

import { MyToverAPI } from 'api/MyToverAPI';
import { useAuth } from 'contexts/AuthContext';
import CenteredCircularProgress from 'components/page/CenteredCircularProgress';
import { Dialog } from 'components/shared/Dialog';

type EULADialogProps = {
  open: boolean;
  close: () => void;
};

export const EULADialog: FC<EULADialogProps> = ({ open, close }) => {
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [EULA, setEULA] = useState<EULA>({} as EULA);
  const [error, setError] = useState('');

  const createMarkup = () => {
    return { __html: EULA.body };
  };

  const navigateToSignin = () => {
    navigate('/signin');
  };

  const rejectAgreement = async () => {
    try {
      await auth.signout();
      close();
    } finally {
      enqueueSnackbar(t('eula.dialog.snackbar.content'), {
        variant: 'info',
        action: () => (
          <Link onClick={navigateToSignin} sx={{ cursor: 'pointer' }} underline="always" fontWeight="bold">
            {t('eula.dialog.snackbar.action')}
          </Link>
        ),
      });
    }
  };

  const acceptAgreement = async () => {
    try {
      setLoading(true);
      const { status } = await MyToverAPI.acceptEULA(EULA.hash);
      if (status) close();
    } catch (error: unknown) {
      const { data } = error as AxiosResponse;
      if (data?.message) setError(t(data.message));
    } finally {
      setLoading(false);
    }
  };

  const getAgreement = async () => {
    try {
      if (!loading) setLoading(true);
      const { status, parsed } = await MyToverAPI.getEULA();
      if (status) {
        setEULA(parsed.data);
      }
    } catch (error) {
      setError(t('error.404.eula'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) getAgreement();

    return () => setEULA({} as EULA);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (loading) setError('');
  }, [loading]);

  return (
    <Dialog.Container open={open} onClose={close} disableEscapeKeyDown>
      <Dialog.Title>{t('eula.dialog.title')}</Dialog.Title>
      <Dialog.Content>
        {loading ? (
          <CenteredCircularProgress />
        ) : error ? (
          <Alert
            severity="error"
            action={
              <Button color="error" size="small" onClick={getAgreement}>
                {t('eula.dialog.retry')}
              </Button>
            }
          >
            {error}
          </Alert>
        ) : (
          <DialogContentText
            component="article"
            dangerouslySetInnerHTML={createMarkup()}
            sx={{
              overflowX: 'hidden',
              maxHeight: '50vh',
            }}
          />
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          target="_top"
          href={`${process.env.REACT_APP_MYTOVER_API}/downloads/eula`}
          color="secondary"
          startIcon={<Download />}
          sx={{
            '&:not(:last-child)': {
              mr: 'auto',
            },
          }}
        >
          {t('eula.dialog.download')}
        </Button>
        <Button disabled={loading || !!error} onClick={rejectAgreement} variant="text">
          {t('eula.dialog.reject')}
        </Button>
        <Button disabled={loading || !!error} onClick={acceptAgreement}>
          {t('eula.dialog.accept')}
        </Button>
      </Dialog.Actions>
    </Dialog.Container>
  );
};
