import slugify from 'slugify';

interface SlugifySettings {
  replacement?: string; // replace spaces with replacement character, defaults to `-`
  remove?: RegExp; // remove characters that match regex, defaults to `undefined`
  lower?: boolean; // convert to lower case, defaults to `false`
  strict?: boolean; // strip special characters except replacement, defaults to `false`
  locale?: string; // language code of the locale to use
  trim?: boolean; // trim leading and trailing replacement chars, defaults to `true`
}

export function stringToSlug(str: string, options: SlugifySettings) {
  return slugify(str, options);
}
