import { ElementType, FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '@mui/material';

interface RegisterButtonProps extends ButtonProps<ElementType> {
  contrast?: boolean;
}

export const RegisterButton: FC<RegisterButtonProps> = ({ contrast, sx, ...props }) => {
  const { t } = useTranslation();

  return (
    <Button
      color={contrast ? 'inherit' : 'secondary'}
      component={Link}
      sx={{
        color: contrast ? 'secondary.main' : 'common.white',
        ...sx,
      }}
      to="/request-invite"
      {...props}
    >
      {t('opl.link')}
    </Button>
  );
};
