import { createBrowserRouter } from 'react-router-dom';

import { Layout } from 'components/app/Layout';
import { NotFound } from 'components/app/NotFound';

import { AccountRoutes } from './routes/AccountRoutes';
import { LegalRoutes } from './routes/LegalRoutes';
import { ManagementRoutes } from './routes/ManagementRoutes';
import { RootRoutes } from './routes/RootRoutes';
import { ServiceCenterRoutes } from './routes/ServiceCenterRoutes';
import { StudioRoutes } from './routes/StudioRoutes';
import { TovertafelRegistrationRoutes } from './routes/TovertafelRegistrationRoutes';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      ...RootRoutes,
      ...AccountRoutes,
      ...ServiceCenterRoutes,
      ...ManagementRoutes,
      ...TovertafelRegistrationRoutes,
      ...StudioRoutes,
      ...LegalRoutes,
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]);
