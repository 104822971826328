import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollTopOnNavigation = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash === '' || hash === undefined) {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        let element: HTMLElement | null;

        element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        } else {
          element = document.querySelector(`[name="${id}"]`);
          if (element instanceof HTMLElement) {
            element.scrollIntoView();
          }
        }
      }, 1000); // TODO: what if loading the site takes too long?
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
};
