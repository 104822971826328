import { GlobalAction, GlobalState } from 'interfaces/GlobalContext';
import { Locale } from 'interfaces/Locale';

export const SET_LANGUAGES = 'SET_LANGUAGES';

export const initialState: GlobalState = {
  languages: [],
};

export const init = (initialState: GlobalState) => initialState;

export const reducer = (state: any, action: GlobalAction) => {
  switch (action.type) {
    case SET_LANGUAGES:
      return setLanguages(action.languages, state);
    default:
      throw new Error();
  }
};

const setLanguages = (languages: Locale[], state: GlobalState) => {
  return { ...state, languages };
};
