import { FC, useEffect, useState } from 'react';
import { Box, Fade, styled } from '@mui/material';

import { MyToverLogo } from 'components/shared/MyToverLogo';

type PageLoaderProps = {
  determined: boolean;
};

const Pulsating = styled('div')({
  '@keyframes pulsate': {
    '0%': {
      opacity: 0.6,
      transform: 'scale(1)',
    },
    '45%': {
      opacity: 0.6,
      transform: 'scale(1)',
    },
    '55%': {
      opacity: 1,
      transform: 'scale(1.2)',
    },
    '100%': {
      opacity: 0.6,
      transform: 'scale(1)',
    },
  },
  'animation': 'pulsate 1500ms infinite ease',
  'position': 'relative',
  'zIndex': 3,
});

export const PageLoader: FC<PageLoaderProps> = ({ determined }) => {
  const [isPastDelay, setIsPastDelay] = useState(false);
  const [isVisible, setVisible] = useState(true);
  const enableTransition = isPastDelay && determined;

  const transitionOut = () => {
    return setTimeout(() => {
      setVisible(false);
    }, 500);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsPastDelay(true);
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    if (enableTransition) {
      const timeoutId = transitionOut();

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [enableTransition]);

  return isVisible ? (
    <Box
      sx={{
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'center',
        'width': '100%',
        'height': '100%',
        'position': 'fixed',
        'top': 0,
        'left': 0,
        'right': 0,
        'bottom': 0,
        'visibility': 'visible',
        'zIndex': 999999,
        '&::before': {
          display: 'block',
          content: '""',
          width: '100%',
          height: '100%',
          backgroundColor: 'secondary.main',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1,
          opacity: 0.8,
          transition: 'opacity 600ms ease-in-out',
          transitionTimingFunction: 'ease-in-out',
          transitionDelay: '100ms',
          ...(enableTransition ? { opacity: 0 } : {}),
        },
        '&::after': {
          display: 'block',
          content: '""',
          width: '100%',
          height: '100%',
          transform: 'scale(2.2) translate(0, 0)',
          backgroundColor: 'secondary.main',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 2,
          transition: 'transform 600ms ease-in-out',
          transitionTimingFunction: 'ease-in-out',
          ...(enableTransition ? { transform: 'scale(2.2) translate(0%, 100%)' } : {}),
        },
      }}
    >
      <Fade in={!enableTransition}>
        <Pulsating>
          <MyToverLogo sx={{ color: 'common.white' }} />
        </Pulsating>
      </Fade>
    </Box>
  ) : null;
};
