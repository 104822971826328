import { StudioAction, StudioState } from 'interfaces/StudioContext';
import { ContentSet } from 'interfaces/StudioTypes';
import { Unit } from 'interfaces/Unit';

export const GET_SETS = 'GET_SETS';
export const GET_UNIT = 'GET_UNIT';
export const RESET = 'RESET';

export const initialState: StudioState = {
  sets: [],
  currentUnit: undefined,
};

export const init = (initialState: StudioState) => initialState;

export const reducer = (state: any, action: StudioAction) => {
  switch (action.type) {
    case GET_SETS:
      return getSets(state, action.sets);
    case GET_UNIT:
      return getUnit(state, action.unit);
    case RESET:
      return init(initialState);
    default:
      throw new Error();
  }
};

const getSets = (state: StudioState, sets?: ContentSet[]) => {
  if (!sets) return state;
  return { ...state, sets };
};

const getUnit = (state: StudioState, unit?: Unit) => {
  if (!unit) return state;
  return { ...state, currentUnit: unit };
};
