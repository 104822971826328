import { Dispatch, FC, SetStateAction, useState } from 'react';
import { NavItem } from 'interfaces/NavTypes';
import { Collapse, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { getRouteProps } from 'utils/getRouteProps';

interface DrawerMenuItemProps extends NavItem {
  toggleDrawer: Dispatch<SetStateAction<boolean>>;
}

export const DrawerMenuItem: FC<DrawerMenuItemProps> = ({ toggleDrawer, ...item }) => {
  const isExpandable = !!item.children?.length;
  const [open, setOpen] = useState(false);

  const NavItemRoot = () => {
    const { component, ...props } = getRouteProps(item);

    return (
      <ListItem
        {...props}
        component={isExpandable ? 'li' : component}
        onClick={() => (isExpandable ? setOpen(!open) : toggleDrawer(false))}
        sx={{
          ':where(li, a):not(:only-of-type)': { pl: 4 },
          '&:hover': { bgcolor: 'primary.main', cursor: 'pointer' },
        }}
      >
        <ListItemText primary={<Typography variant={isExpandable ? 'body1' : 'button'}>{item.title}</Typography>} />
        {isExpandable ? (
          <ListItemSecondaryAction onClick={() => setOpen(!open)}>
            <IconButton edge="end" aria-label="expand">
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </ListItemSecondaryAction>
        ) : null}
      </ListItem>
    );
  };

  const NavItemChildren = () => {
    return isExpandable ? (
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding>
          {item.children?.map((child, index) => <DrawerMenuItem {...child} key={index} toggleDrawer={toggleDrawer} />)}
        </List>
      </Collapse>
    ) : null;
  };

  return (
    <>
      <NavItemRoot />
      <NavItemChildren />
    </>
  );
};
