import { FC, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Button, Menu, MenuItem, Tooltip } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { useAuth } from 'contexts/AuthContext';

export const HeaderAvatarMenu: FC = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const {
    state: { account },
    signout,
  } = useAuth();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignout = async () => {
    await signout();
    setAnchorEl(null);
    navigate('/');
  };

  return (
    <Box>
      <Box>
        <Tooltip title={t('header.avatar-menu.tooltip')}>
          <Button
            id="account-menu-button"
            color={open ? 'primary' : 'secondary'}
            endIcon={open ? <ExpandLess /> : <ExpandMore />}
            onClick={handleClick}
            variant="contained"
            sx={{
              'border': 'none',
              '&:hover': {
                bgcolor: 'primary.main',
              },
              ...(open && {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }),
            }}
          >
            <Avatar src={account?.profile_image?.url} />
          </Button>
        </Tooltip>
      </Box>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'account-menu-button',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            'bgcolor': 'primary.main',
            'borderRadius': 2,
            ...(open && {
              borderTopRightRadius: 0,
            }),
            'overflow': 'visible',
            'minWidth': 200,
            '& .MuiMenuItem-root:hover': {
              bgcolor: 'primary.light',
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        disableScrollLock
      >
        {auth.can('manage.own.account') && (
          <MenuItem component={RouterLink} onClick={handleClose} to="/account">
            {t('header.avatar-menu.account')}
          </MenuItem>
        )}

        <MenuItem onClick={handleSignout}>{t('user.signout')}</MenuItem>
      </Menu>
    </Box>
  );
};
