import { Box, Breakpoint, ContainerProps, Theme, Typography, useMediaQuery } from '@mui/material';

import PageBreadcrumbs from './PageBreadcrumbs';
import PageSectionContainer from './PageSectionContainer';

interface PageHeaderProps extends ContainerProps {
  button?: any;
  crumbs?: any;
  description?: string;
  display?: string;
  gridColumn?: string | number;
  maxWidth?: Breakpoint | false;
  small?: boolean;
  hideUnderline?: boolean;
  title?: string;
}

const PageHeaderSection = ({
  button,
  children,
  crumbs,
  description,
  display,
  gridColumn = 'span 12',
  maxWidth,
  small,
  sx,
  title,
  hideUnderline,
}: PageHeaderProps) => {
  const smallSize = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  return (
    <PageSectionContainer
      component="header"
      display={display}
      maxWidth={maxWidth}
      sx={{
        position: 'relative',
        py: 0,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gridColumn,
          justifyContent: 'space-between',
          position: 'relative',
          pt: small ? 6 : 12,
          pb: 4,
          ...(!hideUnderline
            ? {
                '&::after': {
                  backgroundColor: '#f1be48',
                  content: '""',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  display: 'block',
                  height: '4px',
                  width: '100px',
                },
              }
            : {}),
          ...sx,
        }}
      >
        {crumbs && <PageBreadcrumbs crumbs={crumbs} />}
        {(title || description || button) && (
          <Box display="flex" flex={1} flexWrap={smallSize ? 'wrap' : 'nowrap'} justifyContent="space-between">
            {(title || description) && (
              <Box flex={1}>
                {title && (
                  <Typography variant="h1" gutterBottom={!small}>
                    {title}
                  </Typography>
                )}

                {description && (
                  <Typography variant="subtitle1" gutterBottom>
                    {description}
                  </Typography>
                )}
              </Box>
            )}

            {button && (
              <Box flex={0} whiteSpace="nowrap" flexBasis={smallSize ? '100%' : 'auto'}>
                {button}
              </Box>
            )}
          </Box>
        )}
        {children && (
          <Box flexBasis="100%" paddingY={1}>
            {children}
          </Box>
        )}
      </Box>
    </PageSectionContainer>
  );
};

export default PageHeaderSection;
