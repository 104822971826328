import { Dispatch, FC, SetStateAction } from 'react';
import { Box, Divider, List, SwipeableDrawer } from '@mui/material';

import { useAuth } from 'contexts/AuthContext';
import { RegisterButton } from 'components/buttons/RegisterButton';
import { SignInButton } from 'components/buttons/SignInButton';

import { DrawerMenuItem } from './components';

type DrawerMenuProps = {
  open: boolean;
  toggleDrawer: Dispatch<SetStateAction<boolean>>;
};

export const DrawerMenu: FC<DrawerMenuProps> = ({ open, toggleDrawer }) => {
  const auth = useAuth();

  if (!auth.menu.length) return null;

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
      ModalProps={{ keepMounted: true }}
      sx={{
        'maxWidth': 320,
        '& .MuiDrawer-paper': {
          maxWidth: 320,
        },
      }}
    >
      <Box sx={{ overflow: 'auto', pt: 10 }}>
        <List sx={{ py: 0 }}>
          {auth.menu.map((item, index) => (
            <DrawerMenuItem key={index} {...item} toggleDrawer={toggleDrawer} />
          ))}
        </List>
      </Box>
      <Divider variant="middle" />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mx: 2, my: 3 }}>
        <SignInButton toggleDrawer={toggleDrawer} />
        {(!auth.state.account || auth.hasRole('guest')) && <RegisterButton />}
      </Box>
    </SwipeableDrawer>
  );
};
